form.wpcf7-form{
   label{
    margin: 1em 0 1em 0;
    font-size: medium;
   }
    .form-field{
        background-color: #f0f0f0;
        padding:0.8em;
        .fa{
            position: absolute;
            top: 50%;
            transform: translateY(50%);
            left: 2em;
            color:gray;
        }

        .form-group{
            padding-left:1em;
            margin:0;
        }

        input{
            outline: 0;;
            border: none;
            background-color: #f0f0f0;
            &:focus{
                border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
        textarea{
            border: none;
            background-color: #f0f0f0;
            height: 200px;
            &:focus{
                border-color: inherit;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
        }
    }
}   

.wpcf7-response-output{
    &.alert {
        font-weight: 600;
        color: red;     
    }
}