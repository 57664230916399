/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
	margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 5px auto;
}

.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 20px 20px;
}

a img.alignnone {
	margin: 5px 20px 20px 0;
}

a img.alignleft {
	float: left;
	margin: 5px 20px 20px 0;
}

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption {
	background: #fff;
	border: 1px solid #f0f0f0;
	max-width: 96%;
	/* Image does not overflow the content area */
	padding: 5px 3px 10px;
	text-align: center;
}

.wp-caption.alignnone {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
	margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
	margin: 5px 0 20px 20px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 98.5%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	margin: 0;
	padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
	/* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

body {
	font-family: Open Sans;
	line-height: 20px;
	font-weight: 400;
	font-style: normal;
	color: #777777;
	font-size: 13px;
}

a,
a:hover,
a:focus {
	text-decoration: none;
	color: #1e428e;
}

body {
	line-height: 20px;
}

p {
	line-height: 1.8rem;
	font-size: 1.1rem;
	color: rgb(20, 20, 20);
}



h1,
h2,
h3,
h4,
h5,
h6 {
	color: black;
	line-height: 1.4;
}

h1 {
	font-size: 2.2rem;
}

h2 {
	font-size: 2rem;
}

h3 {
	font-size: 1.6875rem;
}

h4 {
	font-size: 1.4375rem;
}

h5 {
	font-size: 1.125rem;
}

h6 {
	font-size: 1rem;
}

h1>a,
h2>a,
h3>a,
h4>a,
h5>a,
h6>a {
	color: inherit;
}

a {
	color: #222;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

/* ------------------------------------------------------- End Typography --------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------------------------------- */

header {

	background: rgba(0, 0, 0, 0.8);
	position: fixed;
	width: 100%;
	top: 0;
	/* start out at position 0 */
	padding: 0 0;
	z-index: 1;
}

.affix {
	background-color: #fff;
	-webkit-box-shadow: 0px 5px 12px -3px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px 5px 12px -3px rgba(0, 0, 0, 0.75);
	box-shadow: 0px 5px 12px -3px rgba(0, 0, 0, 0.75);
	transition: all 0.5s ease 0s;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
	width: 100%;
}

.shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 10000;
}



#menu {
	margin: 0;
	padding: 0;

}

#menu li {
	position: relative;
	list-style-type: none;
	/* display: inline-block; */
	/* padding: 0px 20px */
}

#menu a {
	/* color: #222; */
	transition: 0.4s;
	/* margin: 8px 0 0 30px; */
	/* border: solid transparent; */
	/* border-width: 0 0 1px 0; */
	/* padding: 5px 0; */
	padding: 10px 20px;
	font-size: 18px;

}

.header #menu>li>a,
.header .navbar-brand {
	color: #fff;
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.affix .navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.affix{
	.navbar-brand>img{
		height: 20px;
	}
}


 .navbar-brand>img{
	max-width: 100%;
	
} 



.navbar-brand {
	
	
	
	

}

.affix .navbar-brand {
	background-image: url('../images/expateam-top.png');
	height: 25px;
	background-position-y: 0px;
}


.affix #menu>li>a,
.affix .navbar-brand {
	color: #222;
}

#menu .active a,
#menu a:hover,
#menu a:focus {
	background-color: #0d4cab;
	color: #fafafa !important;
}

#menu li ul {
	width: 100%;
	top: 33px;
	opacity: 0;
	text-align: left;
	position: absolute;
	padding: 0;
	list-style: none;
	min-width: 180px;
	display: none;
	z-index: 100;
	color: #0d4cab;
	transition: all 1s ease-in-out;
	background-color: #e8e3e3;
	/* background-color: #fff; */
}


#menu ul li {
	position: relative;
}

#menu li:hover>ul {
	opacity: 1;
	display: block;
}



#menu ul li a {
	padding: 15px;
	color: #222;
	display: block;
	margin: 0;
	border-bottom: transparent;
}

#menu ul li a:hover {
	color: #fefefe;
}

#slick {
	margin: 0 auto;
	max-width: 1920px;
	overflow: hidden;
}

.outside {
	height: 70vh;
	position: relative;
	opacity: 1;
	/* background-attachment: fixed;
	background-position: center; */
	background-repeat: no-repeat;
	background-size: cover;
}

.outside img {
	width: 100%;
	height: auto;
}

.inside {
	top: 50%;
	transform: translateY(-50%);
	position: absolute;
	transition: t;
	z-index: 999;
	width: 100%;
	.content{
		h1{
			
		}
		h2{
			font-size: 1.8em;
		}
	}
	
	// background-color: rgba(255, 255, 255, 0.7);
	
}

.inside .blackColour {
	/* background: #fff; */

	position: relative;
	z-index: 100000000;
}

.inside p {
	font-size: 18px;
	color: red;
	padding: 10px;
	margin: 0 !important;
}

.inside img {
	width: auto;
	height: auto;
	margin: 0 auto;
}

#slick .slide.slick-slide .title {
	padding: 0;
}

.slick-list,
.slick-track {
	height: 100%;
}

.slick-dots {
	padding: 0;
	/* margin: 30px 20px 0; */
	text-align: right;
}

.slick-dots li {
	display: inline-block;
}

.slick-slider:hover .slick-next,
.slick-slider:hover .slick-prev {
	opacity: 1;
}

.slick-prev,
.slick-next {
	transform: translateY(-50%);
	transition: 0.4s;
	opacity: 0;
	font-size: 0;
	line-height: 0;
	position: absolute;
	top: 50%;
	display: block;
	width: 40px;
	height: 43px;
	margin-top: -21px;
	cursor: pointer;
	color: #f0f0f0;
	font-size: 40px;
	border: none;
	outline: none;

	z-index: 2;
}

.slick-next {
	right: 25px;
	padding: 20px 12px;
}

.slick-prev {
	left: 25px;
	padding: 20px 8px;
}

.slick-dots button {
	width: 15px;
	height: 15px;
	background: #272727;
	border: 0;
	color: transparent;
	margin: 0 5px;
}

.slick-active button {
	background: #a22e09;
}

.pull-right {
	background: #fff;
	height: 768px;
	width: 768px;
	padding: 30px;
}

.center {
	top: 50%;
	transform: translateY(-50%);
	position: relative;
}

.whiteColour {
	color: #fff;
}

.title {
	font-size: 26px;
	margin: 0 0 30px 0;
	padding: 30px 0 20px;
	background: url("../images/title.png") no-repeat bottom;
}

.content p {
	margin: 30px 0 0;
}

.content img {
	max-width: 100%;
	height: auto;
}

#slideshow a {
	display: inline-block;
	padding: 15px;
	transition: 0.4s;
	text-transform: uppercase;
	background: #fcd088;
	color: #fff;
}

#slideshow a:hover,
#slideshow a:focus {
	background: #272727;
}

.blackColour {
	color: #272727;
}

.features h2 {
	font-weight: 700;
	;
	text-align: center;
	font-size: 1.2rem;
	margin: 10px 0 0;
	color: inherit;
	text-transform: uppercase;
}

.grayColour {
	color: #656d6d;
}

.content a {

	transition: 0.4s;
}

.content a:hover {
	color: #1e428e;
}

#services a {
	font-size: 36px;
}

#photos p {
	margin: 0;
}

#photos .title {
	padding-bottom: 30px;
}

#photos img {
	width: 100%;
	height: auto;
	/*margin-top: 30px;*/
}

.blackBackground {
	background: #202529;
}

#photos.side-post a.show {
	position: relative;
	padding: 15px;
	font-size: 24px;
	transition: 0.4s;
	width: 100%;
}

#photos .side-post-title {
	margin: 0;
	padding: 0 0 20px 0;
}

#photos a.show {
	left: 0;
	position: absolute;
	padding: 15px;
	font-size: 24px;
	transition: 0.4s;
	bottom: 0;
	width: 100%;
}

#photos a:hover {
	color: #fcd088;
}

.margin {
	margin: 60px 0 0;
}

#information .col-md-6 {
	padding: 60px 30px 120px;
}

.whiteBackground {
	background: #fcd088;
}

.excerpt {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(37, 46, 53, 0.8);
	padding: 0 30px 30px;
	left: -100%;
	transition: 0.4s;
	z-index: 1;
}

.slick-slide .desc {
	position: absolute;
	left: 0;
	bottom: 0;
	color: white;
	padding: 10px 10px;
	font-size: 18px;
	text-shadow: 1px 1px 2px black;
	background-color: rgba(0, 0, 0, 0.5);
}

a:hover .excerpt {
	left: 0;
	color: #fff;
}

.carousel img {
	width: 100%;
	height: auto;
}

.carousel .show {
	position: relative;
	overflow: hidden;
}

#map {
	height: 450px;
	margin-top: 60px;
}

#navigation {
	height: 30px;
	margin-top: -30px;
}

#navigation a {
	width: 15px;
	height: 15px;
	background: #272727;
	display: inline-block;
	position: relative;
	transition: 0.4s;
}

#navigation .active,
#navigation a:hover {
	background: #fcd088;
}

footer {
	background-color: #1c1c1c;
	padding-top: 20px;
}

footer h5 {
	color: #fff;
}

footer a {
	color: #cdcdcd;
}

footer p {
	color: #cdcdcd;
}

footer ul li {
	list-style-type: none;
	padding-bottom: 0.8rem;
}

footer ul li span.post-date {
	display: block;
	font-size: 11px;
}

footer ul {
	display: inline;
	margin: 0;
}

footer .footer-menu ul li {
	padding-bottom: 1rem;
	list-style-type: none;
	display: inline;
}

.footer-menu li a {
	padding: 10px 5px;

}

.footer-menu li a:hover {
	color: lightslategrey;
}

.footer-menu ul>li>ul {
	display: none;
	;
}

footer #copyright {
	background-color: #000;
}

#kwatery-item-single-details {
	border-top: 1px solid gray;
	margin-top: 20px;
}

.kwatery-item {
	position: relative;
	overflow: hidden;
}

.kwatery-item .caption {
	width: 100%;
	position: absolute;
	right: 0;
	top: -50px;
	text-align: center;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	opacity: 0;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.kwatery-item:hover .caption {
	opacity: 1;
	top: 0;
}

.additional-list {
	margin-left: 30px;
	padding-left: 0;
}

.additional-list li {
	list-style-type: none;
	text-align: left;
	font-size: 12px;
}

.additional-list li:before {
	font-family: "Glyphicons Halflings";
	content: "\e013";
	padding-right: 10px;
	font-size: 10px;
	color: green;
}

#kwatery-category-content .additional-list,
#kwatery-item-single-details .additional-list {
	margin: 20px 0 20px 0;
}

#kwatery-category-content .additional-list li,
#kwatery-item-single-details .additional-list li {
	font-size: 16px;
}

#kwatery-category-content .kwatery-item {
	border-bottom: 1px solid #d6d4d4;
}

.show {
	display: inline-block;
	overflow: hidden;
}

.show img,
.kwatery-item img {
	transition: 0.4s;
}

.show img:hover,
.kwatery-item img:hover {
	transform: scale(1.1);
}

#carousel-gallery .slick-track {
	margin-left: 0;
}

#landing {
	background: #fff url("../images/expateam-logo-small.png") no-repeat center;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 2;
}

@media (max-width: 992px) {

	#menu li {
		text-align: center;
		padding: 10px 0;
	}

	#menu li ul {
		position: relative;
		display: block;
		opacity: 1;
		background-color: transparent;
	}

	/* #logo {
		text-align: center;
	}
	#menu {
		text-align: center;
		margin-top: 15px;
	}
	#menu a {
		margin: 15px 15px 0;
	}
	#menu li {
		display: block;
	}
	.title {
		font-size: 24px;
	}
	header {
		position: relative;
	}
	.excerpt {
		left: 0;
		left: -100%;
	} */
	/* .pull-right {
		max-width: 225px;
		width: auto;
	} */
	#logo img {
		max-width: 100%;
	}

	/* .navbar-collapse.collapse {
		display: none !important;
	}
	.navbar-collapse.collapse.in {
		display: block !important;
	} */
	/* button.hide {
		display: inline-block !important;
		padding: 0;
		border: 0;
		margin-top: 15px;
		color: red;
		background: none;
		font-size: 24px;
	} */

	.social-buttons li {
		display: block;
		margin-top: 5px;
	}

	#slick .blackColour {
		margin-top: 10px;
	}
}



@media (max-width: 768px) {
	.team-section{
		.content{
			text-align: center;
		} 
	}
	.navbar-brand {
		width: 100px;
	}

	.navbar-toggler {
		margin-left: -25px
	}

	.slick-wrapper {
		margin-top: 80px;

		#slick, .content-widget {
			display: none;

			// /* max-height: 480px; */
			// .outside {
			// 	width: 100%;
			// 	height: 200px;
			// 	margin-bottom: 4px;
			// }

			// .inside {
			// 	background-color: transparent;
			// }
		}

		&.custom {
			.slick-dots {
				display: none;
			}
		}
	}



	#slick .blackColour {
		margin-top: 10px;
	}

	.carousel-gallery {
		._thumbnail {
			margin-bottom: 4px;
		}
	}

	.slick-wrapper .logodiv {
		width: 100%;
		text-align: center;
		display: block !important;
		top: 10px;
		left: auto;
		position: relative !important;
	}


	/* #menu ul {
		position: relative;
		display: block;
		width: auto;
	}

	#menu ul li {
		display: block;
		text-align: center;
	} */

	#menu {
		li{
			padding:0;
			a{
				display: block;
			}
		}
		.wpglobus-selector-link {

			ul.sub-menu {

				top: 0;

				li {

					padding: 0;

					a {
						padding: 5px;
						color: white
					}
				}
			}
		}
	}

	.affix #menu ul.submenu .lang-item a {
		color: #222
	}


}

.overflow-hidden {
	overflow: hidden;
}

.blog-article.grid-style .content h1,
.latest_blog .blog-item .content h4,
.recent_news .blog-item h4,
.recent_news.events .blog-item dt .date {
	text-transform: uppercase;
}

.latest_blog .blog-item .content h4 {
	line-height: 1.1em;
	padding: 10px 0px;
	font-size: 18px;
	font-weight: 700;
}

.latest_blog .blog-item .info {
	margin: 0;
	padding: 0;
	list-style: none;
	width: 100%;
	float: left;
	margin-bottom: 10px;
	margin-top: 4px;
	line-height: 20px;
	/* color: #999999; */
	font-size: 12px;
}

.latest_blog .blog-item .info .fa {
	padding-right: 10px;
	color: #8e8e8e;
}

.latest_blog .blog-item .info li {
	float: left;
	margin-right: 11px;
}

.latest_blog .blog-item .after {
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #ebebeb;
	width: 100%;
}

.latest_blog .blog-item .after .info {
	float: left;
	margin: 0;
	padding: 0;
	color: #222;
	width: auto;
	line-height: 20px;

	font-size: 12px;
}

.latest_blog .blog-item .info li {
	float: left;
	margin-right: 11px;
}


.widget-title,
.portfolio_single h4 {
	text-transform: uppercase;
	line-height: 20px;
	letter-spacing: 1px;
	font-weight: 700;
	font-style: normal;
	color: #444444;
	font-size: 15px;
}

.widget-title {
	margin-top: 0;
	/* float: left; */
	width: 100%;
}

aside ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

aside ul li {
	padding: 10px;
	padding-right: 15px;
}

aside ul li {
	position: relative;
	border-bottom: 1px solid #e7e7e7;
	transition: all 1s;
}

aside ul li:hover {
	background-color: #fdfdfd;
}

aside ul li a {
	position: relative;
	width: 100%;
}

aside ul li:after {
	transform: translateY(-50%);
	top: 50%;
	position: absolute;
	content: "\f105";
	font-family: "FontAwesome";
	float: right;
	-webkit-transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	-ms-transition-duration: 0.4s;
	transition-duration: 0.4s;
	right: 5px;
}


.header_page,
.header_page {
	background-color: #fbfbfb;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left top;
}

.header_page {
	width: 100%;
	position: relative;
	float: none;
	z-index: 0;
	margin-top: 80px;
	padding: 40px 0;
}

.header_page.with_subtitle .titles {
	float: left;
	width: 100%;
	position: relative;
}

.header_page.with_subtitle.normal .titles h1 {
	line-height: 25px;
	height: auto;
	float: left;

}

.header_page.with_subtitle.normal .titles h1 {
	text-transform: uppercase;
	font-weight: 600;
	font-style: normal;
	font-size: 20px;
}

.header_page.with_subtitle .titles .subtitle {
	font-size: 1.4rem;
}

.header_page,
.header_page {
	color: #444444;
}

.header_page.with_subtitle.normal .titles h3 {
	text-transform: none;
	font-weight: 400;
	font-style: normal;
	font-size: 13px;
}

.header_page.with_subtitle.normal .titles h3 {
	line-height: 13px;
	color: inherit;
	opacity: 0.7;
	float: left;
	width: 100%;
	margin-top: 4px;
}

.page_parents li:first-child {
	padding-right: 0px;
}

.page_parents li {
	float: left;
	padding-left: 0px;
	position: relative;
	padding-left: 15px;
	font-weight: 400;
	padding-right: 11px;
	font-size: 12px;
	opacity: 0.7;
}

.sidebar {
	padding-bottom: 2rem;
}

.sidebar .post-date {
	font-size: 10px;
	display: block;
}

.sidebar .widget-title {
	color: rgb(21, 69, 157);
}

.btn-bt.default {
	text-align: center;
	cursor: pointer;
	display: inline-block;
	outline: 0;
	position: relative;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	border-width: 2px;
	border-style: solid;
	float: left;
	padding: 10px 38px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-backface-visibility: hidden;
}

.btn-bt.default:hover {
	border: 2px solid;
}

.btn-bt.default:hover {
	color: #222222;
	background: rgba(16, 184, 199, 0);
	border-color: rgba(68, 68, 68, 1);
}

.btn-bt.default:hover span {
	left: -12px;
}

.btn-bt.default span {
	position: relative;
	left: 0;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.btn-bt.default:hover i {
	left: 80%;
}

.btn-bt.default:hover i {
	left: 77%;
	-ms-filter: "alpha(Opacity=100)";
	filter: alpha(opacity=100);
	opacity: 1;
}

.btn-bt.default i {
	font-size: 14px;
	position: absolute;
	top: 50%;
	line-height: 20px;
	margin-top: 1px;
	left: 73%;
	-ms-filter: "alpha(Opacity=0)";
	filter: alpha(opacity=0);
	opacity: 0;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-ms-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.btn-bt.default i {
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}

.blog-article .btn-bt {
	margin-top: 20px;
	float: left;
}

.btn-bt.default {
	color: #222222;
	background: rgba(16, 184, 199, 0);
	border-color: rgba(68, 68, 68, 0.18);
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	letter-spacing: 1.5px;
}

article.latest_blog {
	float: left;
}

.tag-cloud-link {
	display: inline-block;
	color: #fff;
	padding: 4px 6px;
	border-radius: 5px;
	background-color: #f0f0f0;
	margin: 3px;
}

[data-color="8"] {
	background-color: lightblue;
}

[data-color="9"] {
	background-color: lightgray;
}

[data-color="10"] {
	background-color: lightslategray;
}

[data-color="11"] {
	background-color: lightyellow;
}

[data-color="12"] {
	background-color: #444444;
}

.pagination-wrapper {
	clear: both;
	width: 100%;
}

.pagination-wrapper .nav-links,
.post-navigation .nav-links {
	width: 100%;
	text-align: center;
}

.pagination-wrapper .nav-links .prev,
.pagination-wrapper .nav-links .next,
.post-navigation .nav-links .nav-previous a,
.post-navigation .nav-links .nav-next a {
	padding: 3px 20px;
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;

}

.pagination-wrapper .page-numbers {
	padding: 3px 5px;
	background-color: #f8f8f8;
}

.pagination-wrapper a.page-numbers {
	color: #000;
	font-weight: 600;
}

.pagination-wrapper .nav-links a .fa {
	-webkit-transition: all 0.25s ease-in-out;
	-moz-transition: all 0.25s ease-in-out;
	-ms-transition: all 0.25s ease-in-out;
	-o-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.pagination-wrapper .nav-links a:hover,
.post-navigation .nav-links a:hover {
	background-attachment: fixed;
	background-color: #f8f8f8;
	color: #000;
}

.pagination-wrapper .nav-links .prev,
.pagination-wrapper .nav-links .next {
	background-color: transparent
}

.post-navigation .nav-previous,
.post-navigation .nav-next {
	display: inline-block;
}


.navigation .next .fa {
	padding-left: 0.8rem;
}

.navigation .prev .fa {
	padding-right: 0.8rem;
}

.features .feature-icon .fa {
	float: left;
	text-align: center;
	width: 100%;
	line-height: 100px;
	font-size: 48px;
	margin-bottom: 10px;
	z-index: 1000;
	-webkit-transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	-ms-transition-duration: 0.4s;
	transition-duration: 0.4s;
	color: #1e428e;
}




.features .feature-icon .icon_wrapper {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	left: 50%;
	margin-left: -50px;
	text-align: center;
	-webkit-transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	-o-transition-duration: 0.4s;
	-ms-transition-duration: 0.4s;
	transition-duration: 0.4s;
	position: relative;
	background: #f5f5f5;
}

.features .feature-icon:hover .icon_wrapper {
	background-color: #1e428e;
}

.features .feature-icon:hover .fa {
	color: #f5f5f5;
}


.featured-section {
	background-color: #f5f5f5;
}



blockquote {
	background: #f9f9f9;
	border-left: 10px solid rgb(21, 69, 157);
	margin: 1.5em 10px;
	padding: 0.5em 10px;
	quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
	color: #ccc;
	content: open-quote;
	font-size: 4em;
	line-height: 0.1em;
	margin-right: 0.25em;
	vertical-align: -0.4em;
}

blockquote p {
	display: inline;
}

/* forms */

input[type="text"],
input[type="password"],
textarea,
select {
	outline: none;
}

.has-error>.form-control {
	border-color: #dc3545;


}

.has-error>.help-block {
	color: #dc3545;
	font-weight: 300;
}

.has-error>.form-control:focus {
	-webkit-box-shadow: 0px 0px 5px 1px rgba(220, 53, 69, 1);
	-moz-box-shadow: 0px 0px 5px 1px rgba(220, 53, 69, 1);
	box-shadow: 0px 0px 5px 1px rgba(220, 53, 69, 1);
}


.leftsideslick {
	position: absolute;
	height: 100%;
	width: 30vh;
	display: flex;
	justify-content: center;
	width: 100%;
	display: inline-block;
	align-items: center;

}
.wp-block-cover{
	&.no-dim{
		.has-background-dim{
			background-color: transparent !important;
		}
	}
	.wp-block-button{
		&.btn.btn-light{
			a.wp-block-button__link{
				background-color: transparent !important;
				color:inherit;
			}
		}
	} 
	
	h2{
		font-size: 2em;
	}
	h1{
		font-size: 3rem;
	}
	.has-background-dim{
		background-color: rgba(47,58,68,0.9) !important;
		opacity: 100% !important;
	}

	
}

.wp-block-media-text{
	$self: &; //
	&.has-media-on-the-right{
		#{ $self }__content{
			padding-left: 0;
		}
	}
}



@media (max-width: 768px ) {
	

	
}	

@media (max-width: 768px) {
	.header_page.with_subtitle .titles {
		text-align: center;
		width: 100%;
		position: relative;
	}

	.header_page.with_subtitle .titles h1 {
		font-size: 1.8rem;
	}
}


@media (max-width: 1200px) {
	#menu a {
		padding: 10px 10px;
	}
}

.grecaptcha-badge {
	display: none;
}

.wpcf7-response-output.alert {
	padding: 0;
	border: none;
}