.slick-wrapper{
    position: relative;
    .gradient{
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width: 100%;
        background-image: radial-gradient(circle at top left,rgba(255,255,255,0.89) 30%,rgba(255,255,255,0) 80%)

    }
    .logodiv{
        display: flex;
        top: 0;
        width:100%;
        align-items: center;
        justify-items: center;
       
    }

        &.custom {
            .slick-dots{
                position: absolute;
                right:20px;
                bottom:10px;
                z-index: 5;
            }
        }
    
}

