.toolbar-wrapper{
    background-color: #fcfcfc;
    border-bottom:1px solid #f0f0f0;
}

.font-tool{ 
    .list-inline-item{
        margin:0;
        padding:0
    }
    .size{
        padding: 0px 3px;
        &.active{
            color: rgb(21, 69, 157);
            border-bottom: 2px solid rgb(21, 69, 157);
        }
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 700;
        &.btnFontSmall{
            font-size: 1em;
        }

        &.btnFontNormal{
            font-size: 1.3em;
        }

        &.btnFontBig{
            font-size:1.8em
        }
    }    
}

body{
    &.fontSmall{
        p {
            font-size: 1.2em;
        }
        
    }

    &.fontBig{
        p{
            font-size: 1.5em;
        }
       
    }
}

