
@import '~bootstrap/dist/css/bootstrap.css';
// @import '~aos/dist/aos.css';
// @import '~animate.css/animate.css';
@import './animate';
@import './form';
@import '~slick-carousel/slick/slick.css';
// @import '~slick-carousel/slick/slick-theme.css';
@import './style.scss';
@import './fonttools';
@import './headerlogoslick';
@import './elementor';
